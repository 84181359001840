export const keyShop = {
  id: 1,
  title: "Key Shop BMT",
  mail: "keyshopbmt@gmail.com",
  facebook:"https://www.facebook.com/keyshopbmt/",
  adress: "Số 10 Nguyễn Chí Thanh, Phường Tân An, Tp Buôn Ma Thuột, Đắk Lắk",
  phone: "0942975959",
  phones: "0917249595",
  telephone: "0911429595",
};
